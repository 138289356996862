import "./App.css";
import "./Cat-Eyes.css";
import slumhead from "./slumhead.svg";

function App() {
  return (
    <div className='App'>
      <div className='App-header'>
        {/* <h1>SLUM CATS</h1>
        <p>Coming Soon...</p> */}
      </div>
      <div className='Cat-Eyes'>
        <div class='cat'>
          <div class='ear ear-left'></div>
          <div class='ear ear-right'></div>
          <div class='face'>
            <div class='eye eye-left'>
              <div class='eye-pupil'></div>
            </div>
            <div class='eye eye-right'>
              <div class='eye-pupil'></div>
            </div>
            <div class='muzzle'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
